

.project-item-selected{
    width: 100%;
    height: 100%;
    background-color: rgba(216, 216, 216, 0.438);
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: flex-end;
    // justify-content: center;
    // align-items: center;


    &.hide{
        display: none;
    }



    .check__wrap{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background-color: rgb(89, 89, 168);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
        svg{
            fill: white;
        }
}


}